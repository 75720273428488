// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBwTGYnWm2rP1m_4FuZsZLYr_2A-v2YF48",
    authDomain: "zurprices-prod.firebaseapp.com",
    projectId: "zurprices-prod",
    storageBucket: "zurprices-prod.appspot.com",
    messagingSenderId: "775178686337",
    appId: "1:775178686337:web:8d9d2600ae09d2e0de2280",
    measurementId: "G-H41SRX4RXQ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
