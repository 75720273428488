<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar1">
    <div class="container" appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
        <!-- LOGO -->
        <a class="navbar-brand logo text-uppercase" routerLink="/">
            Zurprices
        </a>

        <button class="navbar-toggler" (click)="toggleMenu()" type="button" id="menu_button" data-toggle="collapse"
            data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
            aria-label="Toggle navigation">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav navbar-center" id="mySidenav">
                <li class="nav-item">
                    <a data="id_home" [ngClass]="{'active':curentsection === 'home'}" [ngxScrollTo]="'#home'"
                        class="nav-link">
                        {{ 'header.home' | translate }}
                    </a>
                </li>
                <!--                 <li class="nav-item">
                    <a data="id_about" [ngClass]="{'active':curentsection === 'about'}" [ngxScrollTo]="'#about'" class="nav-link">Equipo </a>
                </li> -->
                <!--                 <li class="nav-item">
                    <a data="id_features" [ngClass]="{'active':curentsection === 'features'}" [ngxScrollTo]="'#features'" class="nav-link">Productos</a>
                </li> -->
                <li class="nav-item">
                    <a data="id_services" [ngClass]="{'active':curentsection === 'services'}"
                        [ngxScrollTo]="'#services'" class="nav-link">
                        {{ 'header.services' | translate }}
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a data="id_pricing" [ngClass]="{'active':curentsection === 'pricing'}" [ngxScrollTo]="'#pricing'" class="nav-link">Pricing</a>
                </li> -->
                <!-- <li class="nav-item">
                    <a data="id_testi" [ngClass]="{'active':curentsection === 'blog'}" [ngxScrollTo]="'#blog'"
                        class="nav-link">
                        {{ 'header.testi' | translate }}
                    </a>
                </li> -->
                <li class="nav-item">
                    <a data="id_contact" [ngClass]="{'active':curentsection === 'contact'}" [ngxScrollTo]="'#contact'"
                        class="nav-link">
                        {{ 'header.contact' | translate }}
                    </a>
                </li>
                <li class="nav-item d-flex">
                    <select class="nav-link" (change)="languageChange($event.target.value)">
                        <option *ngFor="let lang of languages" [value]="lang.value">{{lang.description}}</option>
                    </select>
                </li>
            </ul>
            <div class="nav-button ms-auto">
                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <button type="button" class="btn btn-primary navbar-btn btn-rounded waves-effect waves-light"
                            (click)="openModal(template)">
                            {{ 'header.client_panel' | translate }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<ng-template #template>
    <div class="p-4">
        <div class="d-flex justify-content-between mb-4">
            <h4 class="m-0">
                {{ 'header.login_modal.access' | translate }}
            </h4>
            <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
        </div>
        <div class="mt-3">
            <form [formGroup]="loginForm" class="registration-form">
                <input type="text" class="form-control registration-input-box"
                    placeholder="{{ 'header.login_modal.email' | translate }}*" formControlName="email" required>
                <input type="password" class="form-control registration-input-box"
                    placeholder="{{ 'header.login_modal.password' | translate }}*" formControlName="password" required>
            </form>
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary w-50 waves-effect waves-light d-flex justify-content-center"
                    (click)="login()">
                    {{ 'header.login_modal.login' | translate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>