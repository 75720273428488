import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FirebaseContactsService } from 'src/services/firebase-contacts.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

// footer component
export class FooterComponent implements OnInit {

  public newsletter = this.fb.group({
    email: ['', [Validators.email, Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
  });
  public emailSent: boolean = false;
  public sendingEmail: boolean = false;

  switch = 1;
  cur_year = 0;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private contactService: FirebaseContactsService,
  ) {
  }

  ngOnInit() {
    this.cur_year = new Date().getFullYear();
  }

  onChangeSwitch() {
    document.getElementById('style-switcher')?.classList.toggle('show')
    // this.switch == 1 ? this.switch = 0 : this.switch = 1;
  }
  onChangeColor(color: string) {
    document.getElementById('color-opt').setAttribute('href', 'assets/css/colors/' + color + '.css');
    // document.getElementById("theme_id").className = "";
    // document.getElementById('theme_id').classList.add('theme-'+color);
  }

  onChangeMode() {
    let theme = localStorage.getItem("theme");
    if (theme == "light" || theme == "") {
      document.body.setAttribute("data-bs-theme", "dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.body.removeAttribute("data-bs-theme");
      localStorage.setItem("theme", "light");
    }
  }

  sendEmail(): void {
    if (this.sendingEmail || this.emailSent) return;
    if (this.newsletter.controls.email.errors?.required) {
      this.toast.error('Debes completar el email para subscribirte');
      return;
    }
    if (this.newsletter.controls.email.errors?.pattern) {
      this.toast.error('Debe respetar el formato de un email');
      return;
    }
    if (this.newsletter.controls.email.errors?.email) {
      this.toast.error('Debe respetar el formato de un email');
      return;
    }
    this.contactService.newsletterSubscribe(this.newsletter.value)
      .then((res) => this.handleSuccess())
      .catch((e) => this.handleError());
  }

  handleSuccess(): void {
    this.emailSent = true;
    this.sendingEmail = false;
    this.toast.success('Su email se ha registrado con éxito');
    this.newsletter.reset();
  }

  handleError(): void {
    this.sendingEmail = false;
    this.toast.error('Ha ocurrido un error enviando tu email. Intente más tarde por favor');
  }
}
